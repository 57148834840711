'use client';
import { classes } from '@/uiPrimitives/base/classes.helpers';
import { box } from '@/uiPrimitives/layout/box';
import { occupy } from '@/uiPrimitives/layout/occupy';
import { placement } from '@/uiPrimitives/layout/placement.css';
import { shelf } from '@/uiPrimitives/layout/shelf';
import { textBlock, textStyle } from '@/uiPrimitives/typography/text';
import { addTestSelector } from '@dop/shared/helpers/testSelector';
import { Translation } from '@/globals/translate/translations';
import { pile } from '@/uiPrimitives/layout/pile';
import { chevronIcon } from '@/uiIcons/chevronIcon.css';
import { icon } from '@/uiPrimitives/media/icon';
import {
	useAreAllItemsOpened,
	useDispatchAccordion,
} from './AccordionProvider.client';

export const AccordionToggleButtons = ({
	itemsCount,
	openAllLabel,
	closeAllLabel,
	className,
}: {
	itemsCount: number;
	openAllLabel?: Translation<'Open all'>;
	closeAllLabel?: Translation<'Close all'>;
	className?: string;
}) => {
	const dispatchAccordion = useDispatchAccordion();
	const areAllItemsOpen = useAreAllItemsOpened(itemsCount);

	if (itemsCount === 0) return null;

	return (
		<div
			className={classes(
				placement({ alignSelf: 'end' }),
				occupy({ blockSize: '0px', alignItems: 'end' }),
				className,
			)}
		>
			<div
				className={classes(
					box({
						paddingBlockEnd: '1 | h3',
					}),
				)}
			>
				<button
					className={classes(
						textStyle({ capSize: '-2 | smaller', color: 'positiveBlue' }),
						shelf({ gap: '-1 | small', alignItems: 'baseline' }),
					)}
					onClick={() => {
						if (areAllItemsOpen) {
							dispatchAccordion({ type: 'closeAll' });
						} else {
							dispatchAccordion({
								type: 'openAll',
								itemsCount,
							});
						}
					}}
					{...addTestSelector('uiToggleAllAccordionsButton')}
				>
					<div className={classes(textBlock({}))}>
						{areAllItemsOpen ? closeAllLabel : openAllLabel}
					</div>
					<div className={classes(occupy({ blockSize: '1ex' }))}>
						<div
							className={classes(
								box({
									borderRadius: 'circle',
									borderStyle: 'solid',
									borderWidth: '1px',
									aspectRatio: '1 / 1',
									blockSize: '2 | h2',
								}),
								pile({ alignItems: 'center', justifyItems: 'center' }),
							)}
						>
							<i
								className={classes(
									icon({
										icon: chevronIcon,
										blockSize: '1ex',
										adjustIcon: 'rotate90deg',
										flip: areAllItemsOpen
											? 'horizontal:hover'
											: 'horizontalNone:hover',
									}),
								)}
							/>
						</div>
					</div>
				</button>
			</div>
		</div>
	);
};
