'use client';

import { PropsWithChildren, ReactNode } from 'react';
import {
	CookieConsentState,
	CookieWidget,
	useCookieConsent,
} from './useCookieConsent';
import {
	addTestSelector,
	testSelectorAttribute,
} from '@dop/shared/helpers/testSelector';

export const CookieConsentButtonClient = ({
	children,
	className,
	widget,
	cookieConsentValue,
	[testSelectorAttribute]: dataUITest,
}: PropsWithChildren<{
	className?: string;
	widget: CookieWidget;
	cookieConsentValue: CookieConsentState;
	[testSelectorAttribute]?: string;
}>) => {
	const [, setConsentState] = useCookieConsent();

	return (
		<button
			className={className}
			onClick={() => {
				setConsentState(cookieConsentValue, widget);
			}}
			{...addTestSelector(dataUITest)}
		>
			{children}
		</button>
	);
};

export const CookieWallClient = ({
	children,
	cookieWallSlot,
	placeholderSlot,
}: PropsWithChildren<{
	cookieWallSlot: ReactNode;
	placeholderSlot: ReactNode;
}>) => {
	const [consentState] = useCookieConsent();

	if (consentState == null) return <>{placeholderSlot}</>;

	if (consentState === 'all') return <>{children}</>;

	return <>{cookieWallSlot}</>;
};
