import {
	CookieConsentState,
	CookieWidget,
} from '@/sections/cookies/useCookieConsent';
import { pushEvent } from '../pushEvent';

export const pushCookieEvent = ({
	cookieConsent,
	oldCookieConsent,
	widget,
}: {
	cookieConsent: CookieConsentState;
	oldCookieConsent: CookieConsentState;
	widget: CookieWidget;
}) => {
	pushEvent({
		events: {
			category: 'interaction.cookiebarChoice',
			action: widget,
			label: `${oldCookieConsent} -> ${cookieConsent}`,
		},
	});
};
