export const youtubeEmbedOrigin = `https://www.youtube.com`;

export const youTubeEventCodes = {
	buffering: 3,
	cued: 5,
	ended: 0,
	paused: 2,
	playing: 1,
	unstarted: -1,
} as const;

export type YouTubeEventNames = keyof typeof youTubeEventCodes;

export type YouTubeEventCode =
	(typeof youTubeEventCodes)[keyof typeof youTubeEventCodes];

const postMessage = (
	targetWindow: Window,
	data: {
		event: string;
		channel: string;
		func?: string;
		args?: Array<string>;
	},
) => {
	const json = JSON.stringify(data);
	targetWindow.postMessage(json, youtubeEmbedOrigin);
};

export const postYouTubePauseEvent = (targetWindow: Window) => {
	return postMessage(targetWindow, {
		event: 'command',
		channel: 'widget',
		func: 'pauseVideo',
	});
};
export const postYouTubePlayEvent = (targetWindow: Window) => {
	return postMessage(targetWindow, {
		event: 'command',
		channel: 'widget',
		func: 'playVideo',
	});
};

export const postYouTubeInitialEvents = (targetWindow: Window) => {
	// Tell YouTube IFrame that we are listening to some of their events
	postMessage(targetWindow, {
		event: 'listening',
		channel: 'widget',
	});
	// Tell YouTube IFrame that we are specifically listening to the onStateChange event
	// to make sure the YouTube iFrame will send this event througt the postMessage API
	postMessage(targetWindow, {
		event: 'command',
		channel: 'widget',
		func: 'addEventListener',
		args: ['onStateChange'],
	});
};

export const addYouTubePostMessageListener = (
	targetWindow: Window,
	eventName: YouTubeEventNames,
	callback: (eventData: { event: string; info: string }) => void,
	{
		signal,
		once,
	}: {
		signal: AbortSignal;
		once?: boolean;
	},
) => {
	const abortController = new AbortController();

	signal.addEventListener(
		'abort',
		() => {
			abortController.abort();
		},
		{ signal: abortController.signal },
	);

	window.addEventListener(
		'message',
		({ origin, data, source }: MessageEvent) => {
			// For security reasons check if origin of message is Youtube
			if (origin !== youtubeEmbedOrigin) return;

			// Check that this message is sent by the iframe in this component
			if (targetWindow !== source) {
				return;
			}

			const eventData = JSON.parse(data);

			if (!eventData) return;

			if (eventData.event !== 'onStateChange') {
				return;
			}

			if (eventData.info !== youTubeEventCodes[eventName]) return;

			callback(eventData);

			if (once) {
				abortController.abort();
			}
		},
		{ signal: abortController.signal },
	);
};
