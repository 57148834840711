'use client';

import { classes } from '@/uiPrimitives/base/classes.helpers';
import {
	PropsWithChildren,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
import { collapseAnimation } from './Collapse.css';
import { overflowClip } from '@/uiPrimitives/utility/overflow.css';
import {
	addTestSelector,
	testSelectorAttribute,
} from '@dop/shared/helpers/testSelector';

export const useCollapseData = (isOpened: boolean) => {
	const [animationSettled, setAnimationSettled] = useState(true);
	const prefersReduceMotionRef = useRef<boolean>(false);
	const settledIsOpenedRef = useRef<boolean>(isOpened);
	useLayoutEffect(() => {
		if (settledIsOpenedRef.current === isOpened) return;
		settledIsOpenedRef.current = isOpened;

		if (!prefersReduceMotionRef.current) {
			setAnimationSettled(false);
		}
	}, [isOpened]);

	useEffect(() => {
		const prefersReduceMotionMatcher = matchMedia(
			'(prefers-reduced-motion: reduce)',
		);

		const abortController = new AbortController();

		prefersReduceMotionRef.current = prefersReduceMotionMatcher.matches;

		prefersReduceMotionMatcher.addEventListener(
			'change',
			(event) => {
				prefersReduceMotionRef.current = event.matches;

				if (prefersReduceMotionRef.current) {
					setAnimationSettled(true);
				}
			},
			{ signal: abortController.signal },
		);

		return () => {
			abortController.abort();
		};
	}, []);

	return {
		animationSettled,
		onTransitionEnd: () => {
			setAnimationSettled(true);
		},
	};
};

export const useCollapse = (isOpened: boolean) => {
	const { animationSettled, onTransitionEnd } = useCollapseData(isOpened);

	return {
		outerProps: {
			className: classes(collapseAnimation[isOpened ? 'opened' : 'closed']),
			onTransitionEnd,
		},
		innerProps: {
			className: classes(!(isOpened && animationSettled) && overflowClip),
			'data-fully-opened': isOpened && animationSettled,
			// Not yet supported by react,
			//  this is a work around to get it in the HTML anyway.
			inert: !isOpened ? ('' as never) : undefined,
		},
	};
};

export const Collapse = ({
	isOpened,
	children,
	id,
	[testSelectorAttribute]: dataUITest,
}: PropsWithChildren<{
	isOpened: boolean;
	id: string;
	[testSelectorAttribute]?: string;
}>) => {
	const { innerProps, outerProps } = useCollapse(isOpened);

	return (
		<span id={id} {...outerProps} {...addTestSelector(dataUITest)}>
			<span {...innerProps}>{children}</span>
		</span>
	);
};
