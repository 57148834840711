'use client';

import {
	Dispatch,
	PropsWithChildren,
	createContext,
	useContext,
	useReducer,
} from 'react';
import { produce } from 'immer';

const AccordionContext = createContext<
	| {
			openedItems: Set<number>;
			dispatchOpenedItems: Dispatch<Parameters<typeof openedItemsReducer>[1]>;
	  }
	| undefined
>(undefined);

const openedItemsReducer = produce(
	(
		openedItemsDraft: Set<number>,
		action:
			| { type: 'toggle'; index: number }
			| { type: 'openAll'; itemsCount: number }
			| { type: 'closeAll' },
	) => {
		switch (action.type) {
			case 'toggle':
				if (openedItemsDraft.has(action.index)) {
					openedItemsDraft.delete(action.index);
					return;
				}

				openedItemsDraft.add(action.index);
				return;

			case 'openAll':
				for (let index = 0; index < action.itemsCount; index += 1) {
					openedItemsDraft.add(index);
				}
				return;

			case 'closeAll':
				openedItemsDraft.clear();
				return;
		}
	},
);

export const AccordionProvider = ({
	children,
	defaultOpened = new Set<number>(),
}: PropsWithChildren<{ defaultOpened?: Set<number> }>) => {
	const [openedItems, dispatchOpenedItems] = useReducer(
		openedItemsReducer,
		defaultOpened,
	);

	return (
		<AccordionContext.Provider value={{ openedItems, dispatchOpenedItems }}>
			{children}
		</AccordionContext.Provider>
	);
};

export const useAccordionItemOpened = (index: number) => {
	const context = useContext(AccordionContext);
	if (context == null) {
		throw new Error(
			'useAccordionItemOpened must be used within a AccordionProvider',
		);
	}

	const { openedItems } = context;

	return openedItems.has(index);
};

export const useAreAllItemsOpened = (openedItemsCount: number) => {
	const context = useContext(AccordionContext);
	if (context == null) {
		throw new Error(
			'useAccordionOpenedItemsCount must be used within a AccordionProvider',
		);
	}

	const { openedItems } = context;

	return openedItems.size === openedItemsCount;
};

export const useDispatchAccordion = () => {
	const context = useContext(AccordionContext);
	if (context == null) {
		throw new Error(
			'useDispatchAccordion must be used within a AccordionProvider',
		);
	}

	return context.dispatchOpenedItems;
};
