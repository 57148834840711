'use client';
import { useEffect, useRef, useState } from 'react';

export const useShowScrollButtons = () => {
	const scrollAreaRef = useRef<HTMLDivElement>(null);
	const measureBeforeRef = useRef<HTMLElement>(null);
	const measureAfterRef = useRef<HTMLElement>(null);
	// Should be undefined before first measure
	const [showBeforeButton, setShowBeforeButton] = useState<boolean | undefined>(
		undefined,
	);
	const [showAfterButton, setShowAfterButton] = useState<boolean | undefined>(
		undefined,
	);

	useEffect(() => {
		const scrollAreaElement = scrollAreaRef.current;
		const beforeElement = measureBeforeRef.current;
		const afterElement = measureAfterRef.current;

		if (
			scrollAreaElement == null ||
			beforeElement == null ||
			afterElement == null
		)
			return;

		const intersectionObserver = new IntersectionObserver(
			(entries) => {
				for (const entry of entries) {
					if (entry.target === beforeElement) {
						setShowBeforeButton(!entry.isIntersecting);
					}

					if (entry.target === afterElement) {
						setShowAfterButton(!entry.isIntersecting);
					}
				}
			},
			{
				root: scrollAreaElement,
			},
		);

		intersectionObserver.observe(beforeElement);
		intersectionObserver.observe(afterElement);

		return () => {
			intersectionObserver.disconnect();
		};
	}, []);

	return {
		scrollAreaRef,
		measureBeforeRef,
		measureAfterRef,
		showBeforeButton,
		showAfterButton,
	};
};
