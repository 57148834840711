import { chevronIcon } from '@/uiIcons/chevronIcon.css';
import { classes } from '@/uiPrimitives/base/classes.helpers';
import { interactive } from '@/uiPrimitives/interactive/interactive';
import { box } from '@/uiPrimitives/layout/box';
import { occupy } from '@/uiPrimitives/layout/occupy';
import { placement } from '@/uiPrimitives/layout/placement.css';
import { shelf } from '@/uiPrimitives/layout/shelf';
import { zIndex } from '@/uiPrimitives/layout/zIndex.css';
import { icon } from '@/uiPrimitives/media/icon';
import { visiblyHidden } from '@/uiPrimitives/utility/display.css';
import {
	addTestSelector,
	testSelectorAttribute,
} from '@dop/shared/helpers/testSelector';
import { ReactNode } from 'react';

const scrollButtonSide = {
	before: {
		adjustIcon: 'flipHorizontal',
	},
	after: {
		adjustIcon: undefined,
	},
} as const;

const ScrollButtonShelf = ({
	children,
	className,
	[testSelectorAttribute]: dataUITest,
}: {
	children: ReactNode;
	className?: string;
	[testSelectorAttribute]?: string;
}) => {
	return (
		<span
			className={classes(
				className,
				shelf({
					gap: '-2 | smaller',
					alignItems: 'center',
					alignContent: 'center',
				}),
			)}
			{...addTestSelector(dataUITest)}
		>
			{children}
		</span>
	);
};

export const ScrollButton = {
	Container: ({
		children,
		className,
		descriptionSlot,
		isShowing = true,
	}: {
		children: ReactNode;
		className?: string;
		descriptionSlot?: ReactNode;
		isShowing?: boolean;
	}) => {
		return (
			<span
				className={classes(
					!isShowing && visiblyHidden,
					shelf({
						gridTemplateColumns: 'minmax(0, 1fr) auto',
						gap: '1 | h3',
					}),
				)}
			>
				{
					<span
						className={classes(
							box({
								paddingBlockStart: '-2 | smaller',
							}),
						)}
					>
						{descriptionSlot}
					</span>
				}
				<span
					className={classes(
						className,

						placement({
							gridRowStart: '1',
							gridColumnEnd: '-1',
						}),
						zIndex({ zIndex: '1' }),
						box({
							blockSize: '0px',
						}),
					)}
				>
					<ScrollButtonShelf
						className={classes(
							box({
								paddingBlockStart: '-2 | smaller',
							}),
						)}
					>
						{children}
					</ScrollButtonShelf>
				</span>
			</span>
		);
	},

	Shelf: ScrollButtonShelf,

	Item: ({
		isShowing,
		buttonLabel,
		onClick,
		side,
		className,
	}: {
		side: 'before' | 'after';
		isShowing: boolean;
		buttonLabel: string;
		onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
		className?: string;
	}) => {
		return (
			<button
				type="button"
				aria-disabled={!isShowing}
				onClick={isShowing ? onClick : undefined}
				className={classes(
					className,
					box({
						inlineSize: 'fit-content',
						padding: '-2 | smaller',
						backgroundColor: isShowing ? 'positiveBlue' : 'negativeGray',
					}),
					!isShowing && interactive({ cursor: 'default' }),
				)}
			>
				<span
					className={classes(
						occupy({
							justifyItems: 'center',
							alignItems: 'center',
							inlineSize: '0 | p',
							blockSize: '0 | p',
						}),
					)}
				>
					<i
						aria-label={buttonLabel}
						className={classes(
							icon({
								icon: chevronIcon,
								adjustIcon: scrollButtonSide[side].adjustIcon,
								blockSize: '0 | p',
								fill: isShowing ? 'negative' : 'positiveMuted',
							}),
						)}
					/>
				</span>
			</button>
		);
	},
};
